/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    APIMessageAuthor,
    APIMessageAuthorFromJSON,
    APIMessageAuthorFromJSONTyped,
    APIMessageAuthorToJSON,
} from './APIMessageAuthor';

/**
 * A collection of serializer extensions, which allow for:

* Blacklisting fields through an "exclude" context variable
* Whitelisting fields through an "only" context variable
* Expanding related fields through an "expand" context variable
* Other helper methods

Blacklisting and whitelisting takes precedence over expanding, and can
be used in combination with one another.
 * @export
 * @interface APIMessage
 */
export interface APIMessage {
    /**
     * 
     * @type {string}
     * @memberof APIMessage
     */
    readonly self: string;
    /**
     * 
     * @type {string}
     * @memberof APIMessage
     */
    readonly messageType: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof APIMessage
     */
    eventData?: { [key: string]: any; } | null;
    /**
     * 
     * @type {Date}
     * @memberof APIMessage
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof APIMessage
     */
    body: string;
    /**
     * 
     * @type {APIMessageAuthor}
     * @memberof APIMessage
     */
    author: APIMessageAuthor | null;
    /**
     * 
     * @type {string}
     * @memberof APIMessage
     */
    readonly channel: string;
    /**
     * 
     * @type {string}
     * @memberof APIMessage
     */
    readonly isNewMessage: string;
}

export function APIMessageFromJSON(json: any): APIMessage {
    return APIMessageFromJSONTyped(json, false);
}

export function APIMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): APIMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'self': json['self'],
        'messageType': json['message_type'],
        'eventData': !exists(json, 'event_data') ? undefined : json['event_data'],
        'createdAt': (new Date(json['created_at'])),
        'body': json['body'],
        'author': APIMessageAuthorFromJSON(json['author']),
        'channel': json['channel'],
        'isNewMessage': json['is_new_message'],
    };
}

export function APIMessageToJSON(value?: APIMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'event_data': value.eventData,
        'body': value.body,
        'author': APIMessageAuthorToJSON(value.author),
    };
}

