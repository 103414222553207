/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A collection of serializer extensions, which allow for:

* Blacklisting fields through an "exclude" context variable
* Whitelisting fields through an "only" context variable
* Expanding related fields through an "expand" context variable
* Other helper methods

Blacklisting and whitelisting takes precedence over expanding, and can
be used in combination with one another.
 * @export
 * @interface APIRole
 */
export interface APIRole {
    /**
     * 
     * @type {string}
     * @memberof APIRole
     */
    readonly self: string;
    /**
     * 
     * @type {string}
     * @memberof APIRole
     */
    readonly subRoles: string;
    /**
     * Guest user roles are roles that are reserved for the guest user type. They work like a normal organization user role, but won't be listed by the RoleViewSet.
     * @type {boolean}
     * @memberof APIRole
     */
    isGuestUserRole?: boolean;
    /**
     * 
     * @type {string}
     * @memberof APIRole
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof APIRole
     */
    description?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof APIRole
     */
    permissions?: { [key: string]: any; };
}

export function APIRoleFromJSON(json: any): APIRole {
    return APIRoleFromJSONTyped(json, false);
}

export function APIRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): APIRole {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'self': json['self'],
        'subRoles': json['sub_roles'],
        'isGuestUserRole': !exists(json, 'is_guest_user_role') ? undefined : json['is_guest_user_role'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'permissions': !exists(json, 'permissions') ? undefined : json['permissions'],
    };
}

export function APIRoleToJSON(value?: APIRole | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'is_guest_user_role': value.isGuestUserRole,
        'name': value.name,
        'description': value.description,
        'permissions': value.permissions,
    };
}

