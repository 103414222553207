/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const APIPlanningEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;
export type APIPlanningEnum = typeof APIPlanningEnum[keyof typeof APIPlanningEnum];


export function APIPlanningEnumFromJSON(json: any): APIPlanningEnum {
    return APIPlanningEnumFromJSONTyped(json, false);
}

export function APIPlanningEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): APIPlanningEnum {
    return json as APIPlanningEnum;
}

export function APIPlanningEnumToJSON(value?: APIPlanningEnum | null): any {
    return value as any;
}

