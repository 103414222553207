/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    APINullEnum,
    APINullEnumFromJSON,
    APINullEnumFromJSONTyped,
    APINullEnumToJSON,
} from './APINullEnum';
import {
    APIPlanningEnum,
    APIPlanningEnumFromJSON,
    APIPlanningEnumFromJSONTyped,
    APIPlanningEnumToJSON,
} from './APIPlanningEnum';

/**
 * @type APICustomerOrderPlanning
 *
 * @export
 */
export type APICustomerOrderPlanning = APINullEnum | APIPlanningEnum;

export function APICustomerOrderPlanningFromJSON(json: any): APICustomerOrderPlanning {
    return APICustomerOrderPlanningFromJSONTyped(json, false);
}

export function APICustomerOrderPlanningFromJSONTyped(json: any, ignoreDiscriminator: boolean): APICustomerOrderPlanning {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return Object.assign(APINullEnumFromJSONTyped(json, true), APIPlanningEnumFromJSONTyped(json, true) );
}

export function APICustomerOrderPlanningToJSON(value?: APICustomerOrderPlanning | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return { ...APINullEnumToJSON(value as APINullEnum), ...APIPlanningEnumToJSON(value as APIPlanningEnum) };
}

