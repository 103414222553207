/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A collection of serializer extensions, which allow for:

* Blacklisting fields through an "exclude" context variable
* Whitelisting fields through an "only" context variable
* Expanding related fields through an "expand" context variable
* Other helper methods

Blacklisting and whitelisting takes precedence over expanding, and can
be used in combination with one another.
 * @export
 * @interface APIAddress
 */
export interface APIAddress {
    /**
     * 
     * @type {string}
     * @memberof APIAddress
     */
    readonly self: string;
    /**
     * 
     * @type {string}
     * @memberof APIAddress
     */
    address1: string;
    /**
     * 
     * @type {string}
     * @memberof APIAddress
     */
    address2?: string;
    /**
     * 
     * @type {string}
     * @memberof APIAddress
     */
    address3?: string;
    /**
     * 
     * @type {string}
     * @memberof APIAddress
     */
    zipCode?: string;
    /**
     * 
     * @type {string}
     * @memberof APIAddress
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof APIAddress
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof APIAddress
     */
    info?: string;
}

export function APIAddressFromJSON(json: any): APIAddress {
    return APIAddressFromJSONTyped(json, false);
}

export function APIAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): APIAddress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'self': json['self'],
        'address1': json['address_1'],
        'address2': !exists(json, 'address_2') ? undefined : json['address_2'],
        'address3': !exists(json, 'address_3') ? undefined : json['address_3'],
        'zipCode': !exists(json, 'zip_code') ? undefined : json['zip_code'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'info': !exists(json, 'info') ? undefined : json['info'],
    };
}

export function APIAddressToJSON(value?: APIAddress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address_1': value.address1,
        'address_2': value.address2,
        'address_3': value.address3,
        'zip_code': value.zipCode,
        'city': value.city,
        'country': value.country,
        'info': value.info,
    };
}

