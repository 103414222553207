import { useTranslation } from "react-i18next";
import { FormFieldType } from "../../Form";
import { ColumnProps } from "antd/es/table";
import moment from "moment";
import { Check, Close } from "@mui/icons-material";
import { SelectOption } from "../../../utils/types";
import { getPKfromSelf, isPrivileged, loadAllStaffUsers, loadCountries } from "../../../utils/functions";
import { Chip, Grid, Stack } from "@mui/material";
import { Offer, PriceObject, Request } from "./types";
import { useUserContext } from "../../../utils/context";
import Button from "../../../components/Button";

type Props = {
    name: 'requestTableColumns'
    | 'offerTableColumns'
    | 'certificateOptions'
    | 'requestFormFields'
    | 'requestFormFieldsDisabled'
    | 'requestFormFieldsStaff'
    | 'offerFormFields'
    | 'offerFormFieldsDisabled'
    | 'offerFormFieldsStaff'
    | 'addressFormFields'
} & (
    RequestTableKwargs
    | OfferTableKwargs
    | CertificateOptionsKwargs
    | RequestFormFieldsKwargs
    | RequestFormFieldsDisabledKwargs
    | RequestFormFieldsStaffKwargs
    | OfferFormFieldsKwargs
    | OfferFormFieldsDisabledKwargs
    | OfferFormFieldsStaffKwargs
    | AddressFormFieldsKwargs
)

type RequestTableKwargs = {
    name: 'requestTableColumns'
    newRequests?: Request[]
    handleOffer?: (e: any, record: any) => void
    handleDecline?: (e: any, record: any) => void
}

type OfferTableKwargs = {
    name: 'offerTableColumns',
    newOffers?: Offer[]
    handleContract?: (e: any, record: any) => void
    handleDecline?: (e: any, record: any) => void
}

type CertificateOptionsKwargs = {
    name: 'certificateOptions'
}

type RequestFormFieldsKwargs = {
    name: 'requestFormFields'
    certificateOptions: SelectOption[]
    selectedProduct: string
}

type RequestFormFieldsDisabledKwargs = {
    name: 'requestFormFieldsDisabled'
}

type RequestFormFieldsStaffKwargs = {
    name: 'requestFormFieldsStaff'
}

type OfferFormFieldsKwargs = {
    name: 'offerFormFields'
}

type OfferFormFieldsDisabledKwargs = {
    name: 'offerFormFieldsDisabled'
}

type OfferFormFieldsStaffKwargs = {
    name: 'offerFormFieldsStaff'
}

type AddressFormFieldsKwargs = {
    name: 'addressFormFields'
}

export default function GetConstant(props: Props) : FormFieldType[] | ColumnProps<any>[] | SelectOption[] {
    const { t } = useTranslation(['common', 'request']);
    const { user } = useUserContext();

    const renderStatus = (status: number) => {
        switch(status) {
            case 0:
                return (
                    <Stack direction="row" spacing={1}>
                        <Chip label={t("request:content.label.pending")} color="warning" variant="outlined" />
                    </Stack>
                )
            case 1:
                return (
                    <Stack direction="row" spacing={1}>
                        <Chip label={t("request:content.label.accepted")} color="success" variant="outlined" />
                    </Stack>
                )
            case 2:
                return (
                    <Stack direction="row" spacing={1}>
                        <Chip label={t("request:content.label.rejected")} color="error" variant="outlined" />
                    </Stack>
                )
            default:
                return (
                    <Stack direction="row" spacing={1}>
                        <Chip label={t("request:content.label.unknown")} color="default" variant="outlined" />
                    </Stack>
                )
        }
    }

    if(props.name === 'requestTableColumns'){
        return [
            {
                title: "",
                dataIndex: 'read',
                key: 'read',
                render: (read: boolean, record: Request) => {
                    const newRequest = props.newRequests?.find(request => request.key === record.key)
                    if(!newRequest) return
                    if(newRequest && newRequest.read === true) return
                    if (newRequest && newRequest.read === false) {
                    return (
                        <Grid container justifyContent="center">
                            <Grid item>
                                {newRequest && newRequest.read === false
                                   && <Chip sx={{backgroundColor:'#90D9F5'}} label={t("common:content.label.new")} />
                                }
                            </Grid>
                        </Grid>
                    )
                    }
                }
            },
            {
                title: t("request:content.label.requestNumber"),
                dataIndex: "self",
                key: "self",
                render: (self: any) => getPKfromSelf(self)
            },
            {
                title: t("request:content.label.article"),
                dataIndex: "article",
                key: "article",
                render: (article: any) => article?.name
            },
            {
                title: t("common:content.label.createdAt"),
                dataIndex: "createdAt",
                key: "createdAt",
                render: (date: moment.MomentInput) => moment(date).format("DD.MM.YYYY")
            },
            {
                title: t("request:content.label.updatedAt"),
                dataIndex: "updatedAt",
                key: "updatedAt",
                render: (date: moment.MomentInput) => moment(date).format("DD.MM.YYYY")
            },
            {
                title: t("request:content.label.destinationCountry"),
                dataIndex: "country",
                key: "country",
            },
            {
                title: t("request:content.label.remarks"),
                dataIndex: "remarks",
                key: "remarks",
            },
            {
                title: t("request:content.label.certificates"),
                dataIndex: "certificates",
                key: "certificates",
            },
            {
                title: t("request:content.label.description"),
                dataIndex: "description",
                key: "description",
            },
            {
                title: t("request:content.label.quantities"),
                dataIndex: "quantities",
                key: "quantities",
                render: (quantities: any) => quantities?.map((q: number) => q).join(", ")
            },
            {
                title: t("request:content.label.wishDate"),
                dataIndex: "wishDate",
                key: "wishDate",
                render: (date: moment.MomentInput) => moment(date).format("DD.MM.YYYY")
            },
            {
                title: t("request:content.label.status"),
                dataIndex: "status",
                key: "status",
                render: (status: number) => renderStatus(status)
            },
            {
                title: t("request:content.label.priority"),
                dataIndex: "priority",
                key: "priority",
                render: (priority: boolean) =>
                    <>
                        {priority ? <Check /> : <Close />}
                    </>
            },
            {
                title: t('common:content.label.editors'),
                key: 'editors',
                dataIndex: 'editors',
                render: (text: string, record: Request) => {
                    if (Array.isArray(record.editors)) {
                      return (
                        <>
                          {record.editors.map((e: any) => (
                            <Chip key={e.id} label={`${e.first_name} ${e.last_name}`} style={{ marginRight: '5px' }} />
                          ))}
                        </>
                      )
                    } else {
                      return ''
                    }
                  },
            },
            {
                title: t('common:content.label.action'),
                key: 'action',
                dataIndex: 'action',
                render: (text: string, record: any) => (
                    <Stack direction="column" spacing={1}>
                        <Button
                            id='offerByAssembleanButton'
                            size="small"
                            variant="contained"
                            onClick={e => props.handleOffer && props.handleOffer(e, record)}
                            disabled={record.status === 2}
                        >
                            {t('request:interaction.button.createOffer')}
                        </Button>
                        <Button
                            id='declineRequestButton'
                            size="small"
                            variant="outlined"
                            color="error"
                            onClick={e => props.handleDecline && props.handleDecline(e, record)}
                            disabled={record.status === 2 || record.status === 1}
                        >
                            {t('request:interaction.button.declineRequest')}
                        </Button>
                    </Stack>
                ),
            },
        ].filter((column) => (!isPrivileged(user, "STAFF") && column.key !== 'editors' && column.key !== "priority" && column.key !== "action") || isPrivileged(user, "STAFF")) as ColumnProps<any>[]
    }

    if(props.name === 'offerTableColumns'){
        return [
            {
                title: "",
                dataIndex: 'read',
                key: 'read',
                render: (read: boolean, record: Offer) => {
                    const newOffer = props.newOffers?.find(offer => offer.key === record.key)
                    if(!newOffer) return
                    if(newOffer && newOffer.read === true) return
                    if (newOffer && newOffer.read === false) {
                    return (
                        <Grid container justifyContent="center">
                            <Grid item>
                                {newOffer && newOffer.read === false
                                   && <Chip sx={{backgroundColor:'#90D9F5'}} label={t("common:content.label.new")} />
                                }
                            </Grid>
                        </Grid>
                    )
                    }
                }
            },
            {
                title: t("request:content.label.offerNumber"),
                dataIndex: "self",
                key: "self",
                render: (self: any) => getPKfromSelf(self)
            },
            {
                title: t("request:content.label.requestNumber"),
                dataIndex: "request",
                key: "request",
                render: (request: any) => request?.self && getPKfromSelf(request.self)
            },
            {
                title: t("request:content.label.article"),
                dataIndex: "article",
                key: "article",
                render: (article: any) => article?.name
            },
            {
                title: t("common:content.label.createdAt"),
                dataIndex: "createdAt",
                key: "createdAt",
                render: (date: moment.MomentInput) => moment(date).format("DD.MM.YYYY")
            },
            {
                title: t("common:content.label.updatedAt"),
                dataIndex: "updatedAt",
                key: "updatedAt",
                render: (date: moment.MomentInput) => moment(date).format("DD.MM.YYYY")
            },
            {
                title: t("request:content.label.remarks"),
                dataIndex: "remarks",
                key: "remarks",
            },
            {
                title: t("request:content.label.prices"),
                dataIndex: "prices",
                key: "prices",
                render: (prices: PriceObject) => {
                    return (
                        <>
                            {Object.keys(prices).map((k: string) => (
                                <div key={k}>
                                    <strong>{k}</strong>: {prices[k] + " €"}
                                </div>
                            ))}
                        </>
                    )
                }
            },
            {
                title: t("request:content.label.status"),
                dataIndex: "status",
                key: "status",
                render: (status: number) => renderStatus(status)
            },
            {
                title: t('common:content.label.editors'),
                key: 'editors',
                dataIndex: 'editors',
                render: (text: string, record: Request) => {
                    if (Array.isArray(record.editors)) {
                      return (
                        <>
                          {record.editors.map((e: any) => (
                            <Chip key={e.id} label={`${e.first_name} ${e.last_name}`} style={{ marginRight: '5px' }} />
                          ))}
                        </>
                      )
                    } else {
                      return ''
                    }
                  },
            },
            {
                title: t('common:content.label.action'),
                key: 'action',
                dataIndex: 'action',
                render: (text: string, record: any) => (
                    <Stack direction="column" spacing={1}>
                        <Button
                            id='acceptOfferButton'
                            size="small"
                            variant="contained"
                            onClick={e => props.handleContract && props.handleContract(e, record)}
                            disabled={record.status === 2}
                        >
                            {t('request:interaction.button.createContract')}
                        </Button>
                        <Button
                            id='declineOfferButton'
                            size="small"
                            variant="outlined"
                            color="error"
                            onClick={e => props.handleDecline && props.handleDecline(e, record)}
                            disabled={record.status === 2 || record.status === 1}
                        >
                            {t('request:interaction.button.declineOffer')}
                        </Button>
                    </Stack>
                ),
            },
        ].filter((column) => (!isPrivileged(user, "STAFF") && column.key !== 'editors') || (isPrivileged(user, "STAFF") && column.key !== "action")) as ColumnProps<any>[]
    }

    if(props.name === "certificateOptions") {
        return [
          { value: 'AS 9100D', label: 'AS 9100D' },
          { value: 'DIN EN ISO 13485', label: 'DIN EN ISO 13485' },
          { value: 'DIN EN ISO 9001', label: 'DIN EN ISO 9001' },
          { value: 'NADCAP', label: 'NADCAP' },
          { value: 'ISO 14001', label: 'ISO 14001' },
          { value: 'IATF 16949', label: 'IATF 16949' },
          { value: 'ISO 27001', label: 'ISO 27001' },
          { value: 'BS7799', label: 'BS7799' },
          { value: 'ISO 50001', label: 'ISO 50001' },
          { value: 'EN ISO 17065', label: 'EN ISO 17065' },
          { value: 'SA8000', label: 'SA8000' },
          { value: 'EMAS', label: 'EMAS' },
          { value: 'DIN EN 1090', label: 'DIN EN 1090' },
          { value: 'DIN EN ISO 3834', label: 'DIN EN ISO 3834' },
          { value: 'DIN 2304', label: 'DIN 2304' },
          { value: 'DIN EN ISO 13585', label: 'DIN EN ISO 13585' },
          { value: 'DIN ISO 11745', label: 'DIN ISO 11745' },
        ] as SelectOption[]
    }

    if(props.name === "requestFormFieldsDisabled") {
        return [
            {
                type: 'text',
                key: 'name',
                label: t('contract:content.label.selectedProduct'),
                xs: 12,
                sm: 6,
            },
            {
                type: 'text',
                key: 'number',
                label: t('contract:content.label.articleNumber'),
                xs: 12,
                sm: 6,
            },
        ]
    }

    if(props.name === "requestFormFields") {
        return [
            {
                type: 'multiline',
                key: 'description',
                label: t('request:content.label.description'),
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
            },
            {
                type: "select-search",
                key: "certificate",
                label: t("common:content.label.certificate"),
                options: props.certificateOptions,
                validation: false,
                required: false,
                xs: 12,
                sm: 6,
            },
            {
                type: 'date',
                key: 'wishDate',
                label: t('request:content.label.wishDate'),
                required: false,
                validation: false,
                xs: 12,
                sm: 6,
            },
        ] as FormFieldType[]
    }

    if(props.name === "requestFormFieldsStaff") {
        return [
            {
                type: 'multiline',
                key: 'remarks',
                label: t('request:content.label.remarks'),
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
            },
            {
                type: 'select-search-multi',
                key: 'editors',
                label: t('common:content.label.editors'),
                options: [] as SelectOption[],
                loadData: loadAllStaffUsers,
                required: false,
                validation: false,
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
            },
            {
                type: 'checkbox',
                key: 'priority',
                label: t('request:content.label.priorityText'),
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
            },
        ]
    }

    if(props.name === "offerFormFields") {
        return [
            {
                type: 'multiline',
                key: 'remarks',
                label: t('request:content.label.remarks'),
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
            }
        ] as FormFieldType[]
    }

    if(props.name === "offerFormFieldsDisabled") {
        return [
            {
                type: 'text',
                key: 'name',
                label: t('contract:content.label.selectedProduct'),
                xs: 12,
                sm: 6,
            },
            {
                type: 'text',
                key: 'number',
                label: t('contract:content.label.articleNumber'),
                xs: 12,
                sm: 6,
            },
        ] as FormFieldType[]
    }

    if(props.name === "offerFormFieldsStaff") {
        return [
            {
                type: 'select-search-multi',
                key: 'editors',
                label: t('common:content.label.editors'),
                options: [] as SelectOption[],
                loadData: loadAllStaffUsers,
                required: false,
                validation: false,
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
            },
            {
                type: 'multiline',
                key: 'remarks',
                label: t('request:content.label.remarks'),
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
            }
        ] as FormFieldType[]
    }

    if(props.name === "addressFormFields") {
        return [
            {
                type: 'select-search',
                key: 'country',
                label: t('request:content.label.destinationCountry'),
                loadData: loadCountries,
                required: true,
                validation: true,
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
        ]
      }

    return [] as FormFieldType[] | ColumnProps<any>[] | SelectOption[];
}