/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Helper serializer that validates that every element of a FeedbackForm or
FeedbackFormSubmission consists of the required properties.
 * @export
 * @interface APIFeedbackFormField
 */
export interface APIFeedbackFormField {
    /**
     * 
     * @type {string}
     * @memberof APIFeedbackFormField
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof APIFeedbackFormField
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof APIFeedbackFormField
     */
    formFieldType: string;
    /**
     * 
     * @type {boolean}
     * @memberof APIFeedbackFormField
     */
    required?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof APIFeedbackFormField
     */
    xl?: number | null;
}

export function APIFeedbackFormFieldFromJSON(json: any): APIFeedbackFormField {
    return APIFeedbackFormFieldFromJSONTyped(json, false);
}

export function APIFeedbackFormFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): APIFeedbackFormField {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'label': json['label'],
        'formFieldType': json['form_field_type'],
        'required': !exists(json, 'required') ? undefined : json['required'],
        'xl': !exists(json, 'xl') ? undefined : json['xl'],
    };
}

export function APIFeedbackFormFieldToJSON(value?: APIFeedbackFormField | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'label': value.label,
        'form_field_type': value.formFieldType,
        'required': value.required,
        'xl': value.xl,
    };
}

