/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APIArticle,
    APIArticleFromJSON,
    APIArticleToJSON,
    APIArticleExport,
    APIArticleExportFromJSON,
    APIArticleExportToJSON,
    APIArticleExportRequest,
    APIArticleExportRequestFromJSON,
    APIArticleExportRequestToJSON,
    APIArticleRequest,
    APIArticleRequestFromJSON,
    APIArticleRequestToJSON,
    APIPaginatedArticleExportList,
    APIPaginatedArticleExportListFromJSON,
    APIPaginatedArticleExportListToJSON,
    APIPaginatedArticleList,
    APIPaginatedArticleListFromJSON,
    APIPaginatedArticleListToJSON,
    APIPatchedArticleRequest,
    APIPatchedArticleRequestFromJSON,
    APIPatchedArticleRequestToJSON,
    APIStorageArticle,
    APIStorageArticleFromJSON,
    APIStorageArticleToJSON,
} from '../models';

export interface V1ItemsArticlesChildrenRetrieveRequest {
    id: number;
}

export interface V1ItemsArticlesCreateRequest {
    aPIArticleRequest: APIArticleRequest;
}

export interface V1ItemsArticlesDestroyRequest {
    id: number;
}

export interface V1ItemsArticlesListRequest {
    page?: number;
    pageSize?: number;
}

export interface V1ItemsArticlesPartialUpdateRequest {
    id: number;
    aPIPatchedArticleRequest?: APIPatchedArticleRequest;
}

export interface V1ItemsArticlesRetrieveRequest {
    id: number;
}

export interface V1ItemsArticlesStorageInfoRetrieveRequest {
    id: number;
}

export interface V1ItemsArticlesUpdateRequest {
    id: number;
    aPIArticleRequest: APIArticleRequest;
}

export interface V1ItemsExportArticlesListRequest {
    page?: number;
    pageSize?: number;
}

export interface V1ItemsImportArticlesCreateRequest {
    number: string;
    weight?: string | null;
    length?: string | null;
    width?: string | null;
    height?: string | null;
    volume?: string | null;
    leadTime?: string | null;
    articleType?: string | null;
    family?: string | null;
    brand?: string | null;
    barcodeType?: string | null;
    supplier?: string | null;
    category?: Array<string>;
    properties?: Array<string>;
    guestRequest?: string | null;
    name?: string;
    barcodeText?: string;
    countryOfOrigin?: string;
    minimumStorage?: number | null;
    minimumOrder?: number | null;
    batchSize?: number | null;
    description?: string;
    isBomBase?: boolean;
    isImportedVersion?: boolean;
}

export interface V1ItemsUndoImportArticlesCreateRequest {
    aPIArticleExportRequest?: APIArticleExportRequest;
}

/**
 * 
 */
export class ItemsarticlesApi extends runtime.BaseAPI {

    /**
     * Method to fetch multiple specific instances at once. THIS METHOD SHOULD BE REPLACED AT SOME POINT!
     */
    async v1ItemsArticlesBatchRequestRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIArticle>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/batch_request/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIArticleFromJSON(jsonValue));
    }

    /**
     * Method to fetch multiple specific instances at once. THIS METHOD SHOULD BE REPLACED AT SOME POINT!
     */
    async v1ItemsArticlesBatchRequestRetrieve(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIArticle> {
        const response = await this.v1ItemsArticlesBatchRequestRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     * Return all articles that are direct children to the specified article.
     */
    async v1ItemsArticlesChildrenRetrieveRaw(requestParameters: V1ItemsArticlesChildrenRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIArticle>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesChildrenRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{id}/children/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIArticleFromJSON(jsonValue));
    }

    /**
     * Return all articles that are direct children to the specified article.
     */
    async v1ItemsArticlesChildrenRetrieve(requestParameters: V1ItemsArticlesChildrenRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIArticle> {
        const response = await this.v1ItemsArticlesChildrenRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesCreateRaw(requestParameters: V1ItemsArticlesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIArticle>> {
        if (requestParameters.aPIArticleRequest === null || requestParameters.aPIArticleRequest === undefined) {
            throw new runtime.RequiredError('aPIArticleRequest','Required parameter requestParameters.aPIArticleRequest was null or undefined when calling v1ItemsArticlesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIArticleRequestToJSON(requestParameters.aPIArticleRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIArticleFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesCreate(requestParameters: V1ItemsArticlesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIArticle> {
        const response = await this.v1ItemsArticlesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesDestroyRaw(requestParameters: V1ItemsArticlesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesDestroy(requestParameters: V1ItemsArticlesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1ItemsArticlesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesListRaw(requestParameters: V1ItemsArticlesListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedArticleList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedArticleListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesList(requestParameters: V1ItemsArticlesListRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedArticleList> {
        const response = await this.v1ItemsArticlesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesPartialUpdateRaw(requestParameters: V1ItemsArticlesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIArticle>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedArticleRequestToJSON(requestParameters.aPIPatchedArticleRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIArticleFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesPartialUpdate(requestParameters: V1ItemsArticlesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIArticle> {
        const response = await this.v1ItemsArticlesPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesRetrieveRaw(requestParameters: V1ItemsArticlesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIArticle>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIArticleFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesRetrieve(requestParameters: V1ItemsArticlesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIArticle> {
        const response = await this.v1ItemsArticlesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * return all StorageArticles that refer to the article where the primary key is equal to pk
     */
    async v1ItemsArticlesStorageInfoRetrieveRaw(requestParameters: V1ItemsArticlesStorageInfoRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIStorageArticle>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesStorageInfoRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{id}/storage_info/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIStorageArticleFromJSON(jsonValue));
    }

    /**
     * return all StorageArticles that refer to the article where the primary key is equal to pk
     */
    async v1ItemsArticlesStorageInfoRetrieve(requestParameters: V1ItemsArticlesStorageInfoRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIStorageArticle> {
        const response = await this.v1ItemsArticlesStorageInfoRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesUpdateRaw(requestParameters: V1ItemsArticlesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIArticle>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesUpdate.');
        }

        if (requestParameters.aPIArticleRequest === null || requestParameters.aPIArticleRequest === undefined) {
            throw new runtime.RequiredError('aPIArticleRequest','Required parameter requestParameters.aPIArticleRequest was null or undefined when calling v1ItemsArticlesUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APIArticleRequestToJSON(requestParameters.aPIArticleRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIArticleFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesUpdate(requestParameters: V1ItemsArticlesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIArticle> {
        const response = await this.v1ItemsArticlesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Export all (non-nested) article-data into a CSV file.
     */
    async v1ItemsExportArticlesListRaw(requestParameters: V1ItemsExportArticlesListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedArticleExportList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/export_articles/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedArticleExportListFromJSON(jsonValue));
    }

    /**
     * Export all (non-nested) article-data into a CSV file.
     */
    async v1ItemsExportArticlesList(requestParameters: V1ItemsExportArticlesListRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedArticleExportList> {
        const response = await this.v1ItemsExportArticlesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A create view that enables importing article data from a CSV file.
     */
    async v1ItemsImportArticlesCreateRaw(requestParameters: V1ItemsImportArticlesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIArticle>> {
        if (requestParameters.number === null || requestParameters.number === undefined) {
            throw new runtime.RequiredError('number','Required parameter requestParameters.number was null or undefined when calling v1ItemsImportArticlesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.weight !== undefined) {
            formParams.append('weight', requestParameters.weight as any);
        }

        if (requestParameters.length !== undefined) {
            formParams.append('length', requestParameters.length as any);
        }

        if (requestParameters.width !== undefined) {
            formParams.append('width', requestParameters.width as any);
        }

        if (requestParameters.height !== undefined) {
            formParams.append('height', requestParameters.height as any);
        }

        if (requestParameters.volume !== undefined) {
            formParams.append('volume', requestParameters.volume as any);
        }

        if (requestParameters.leadTime !== undefined) {
            formParams.append('lead_time', requestParameters.leadTime as any);
        }

        if (requestParameters.articleType !== undefined) {
            formParams.append('article_type', requestParameters.articleType as any);
        }

        if (requestParameters.family !== undefined) {
            formParams.append('family', requestParameters.family as any);
        }

        if (requestParameters.brand !== undefined) {
            formParams.append('brand', requestParameters.brand as any);
        }

        if (requestParameters.barcodeType !== undefined) {
            formParams.append('barcode_type', requestParameters.barcodeType as any);
        }

        if (requestParameters.supplier !== undefined) {
            formParams.append('supplier', requestParameters.supplier as any);
        }

        if (requestParameters.category) {
            formParams.append('category', requestParameters.category.join(runtime.COLLECTION_FORMATS["csv"]));
        }

        if (requestParameters.properties) {
            formParams.append('properties', requestParameters.properties.join(runtime.COLLECTION_FORMATS["csv"]));
        }

        if (requestParameters.guestRequest !== undefined) {
            formParams.append('guest_request', requestParameters.guestRequest as any);
        }

        if (requestParameters.name !== undefined) {
            formParams.append('name', requestParameters.name as any);
        }

        if (requestParameters.number !== undefined) {
            formParams.append('number', requestParameters.number as any);
        }

        if (requestParameters.barcodeText !== undefined) {
            formParams.append('barcode_text', requestParameters.barcodeText as any);
        }

        if (requestParameters.countryOfOrigin !== undefined) {
            formParams.append('country_of_origin', requestParameters.countryOfOrigin as any);
        }

        if (requestParameters.minimumStorage !== undefined) {
            formParams.append('minimum_storage', requestParameters.minimumStorage as any);
        }

        if (requestParameters.minimumOrder !== undefined) {
            formParams.append('minimum_order', requestParameters.minimumOrder as any);
        }

        if (requestParameters.batchSize !== undefined) {
            formParams.append('batch_size', requestParameters.batchSize as any);
        }

        if (requestParameters.description !== undefined) {
            formParams.append('description', requestParameters.description as any);
        }

        if (requestParameters.isBomBase !== undefined) {
            formParams.append('is_bom_base', requestParameters.isBomBase as any);
        }

        if (requestParameters.isImportedVersion !== undefined) {
            formParams.append('is_imported_version', requestParameters.isImportedVersion as any);
        }

        const response = await this.request({
            path: `/v1/items/import_articles/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIArticleFromJSON(jsonValue));
    }

    /**
     * A create view that enables importing article data from a CSV file.
     */
    async v1ItemsImportArticlesCreate(requestParameters: V1ItemsImportArticlesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIArticle> {
        const response = await this.v1ItemsImportArticlesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A create view that enables undoing an article-import by reverting all overwritten articles to their respective pre-import versions and deleting all articles that were created by the import view.
     */
    async v1ItemsUndoImportArticlesCreateRaw(requestParameters: V1ItemsUndoImportArticlesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIArticleExport>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/undo_import_articles/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIArticleExportRequestToJSON(requestParameters.aPIArticleExportRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIArticleExportFromJSON(jsonValue));
    }

    /**
     * A create view that enables undoing an article-import by reverting all overwritten articles to their respective pre-import versions and deleting all articles that were created by the import view.
     */
    async v1ItemsUndoImportArticlesCreate(requestParameters: V1ItemsUndoImportArticlesCreateRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIArticleExport> {
        const response = await this.v1ItemsUndoImportArticlesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
