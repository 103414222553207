/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    APICustomerOrderPlanning,
    APICustomerOrderPlanningFromJSON,
    APICustomerOrderPlanningFromJSONTyped,
    APICustomerOrderPlanningToJSON,
} from './APICustomerOrderPlanning';
import {
    APIStatusDaeEnum,
    APIStatusDaeEnumFromJSON,
    APIStatusDaeEnumFromJSONTyped,
    APIStatusDaeEnumToJSON,
} from './APIStatusDaeEnum';

/**
 * A collection of serializer extensions, which allow for:

* Blacklisting fields through an "exclude" context variable
* Whitelisting fields through an "only" context variable
* Expanding related fields through an "expand" context variable
* Other helper methods

Blacklisting and whitelisting takes precedence over expanding, and can
be used in combination with one another.
 * @export
 * @interface APICustomerOrder
 */
export interface APICustomerOrder {
    /**
     * 
     * @type {string}
     * @memberof APICustomerOrder
     */
    readonly self: string;
    /**
     * 
     * @type {Date}
     * @memberof APICustomerOrder
     */
    orderDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof APICustomerOrder
     */
    startDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof APICustomerOrder
     */
    dueDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof APICustomerOrder
     */
    wishDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof APICustomerOrder
     */
    article: string;
    /**
     * 
     * @type {string}
     * @memberof APICustomerOrder
     */
    customer: string;
    /**
     * 
     * @type {number}
     * @memberof APICustomerOrder
     */
    amount: number;
    /**
     * 
     * @type {APIStatusDaeEnum}
     * @memberof APICustomerOrder
     */
    status?: APIStatusDaeEnum;
    /**
     * 
     * @type {string}
     * @memberof APICustomerOrder
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof APICustomerOrder
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof APICustomerOrder
     */
    customerReference?: string;
    /**
     * 
     * @type {APICustomerOrderPlanning}
     * @memberof APICustomerOrder
     */
    planning?: APICustomerOrderPlanning | null;
    /**
     * 
     * @type {number}
     * @memberof APICustomerOrder
     */
    priority?: number | null;
}

export function APICustomerOrderFromJSON(json: any): APICustomerOrder {
    return APICustomerOrderFromJSONTyped(json, false);
}

export function APICustomerOrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): APICustomerOrder {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'self': json['self'],
        'orderDate': !exists(json, 'order_date') ? undefined : (new Date(json['order_date'])),
        'startDate': (new Date(json['start_date'])),
        'dueDate': !exists(json, 'due_date') ? undefined : (new Date(json['due_date'])),
        'wishDate': !exists(json, 'wish_date') ? undefined : (new Date(json['wish_date'])),
        'article': json['article'],
        'customer': json['customer'],
        'amount': json['amount'],
        'status': !exists(json, 'status') ? undefined : APIStatusDaeEnumFromJSON(json['status']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'customerReference': !exists(json, 'customer_reference') ? undefined : json['customer_reference'],
        'planning': !exists(json, 'planning') ? undefined : APICustomerOrderPlanningFromJSON(json['planning']),
        'priority': !exists(json, 'priority') ? undefined : json['priority'],
    };
}

export function APICustomerOrderToJSON(value?: APICustomerOrder | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'order_date': value.orderDate === undefined ? undefined : (value.orderDate.toISOString()),
        'start_date': (value.startDate.toISOString()),
        'due_date': value.dueDate === undefined ? undefined : (value.dueDate.toISOString()),
        'wish_date': value.wishDate === undefined ? undefined : (value.wishDate.toISOString()),
        'article': value.article,
        'customer': value.customer,
        'amount': value.amount,
        'status': APIStatusDaeEnumToJSON(value.status),
        'description': value.description,
        'comment': value.comment,
        'customer_reference': value.customerReference,
        'planning': APICustomerOrderPlanningToJSON(value.planning),
        'priority': value.priority,
    };
}

