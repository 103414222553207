/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A collection of serializer extensions, which allow for:

* Blacklisting fields through an "exclude" context variable
* Whitelisting fields through an "only" context variable
* Expanding related fields through an "expand" context variable
* Other helper methods

Blacklisting and whitelisting takes precedence over expanding, and can
be used in combination with one another.
 * @export
 * @interface APIOrganization
 */
export interface APIOrganization {
    /**
     * 
     * @type {string}
     * @memberof APIOrganization
     */
    readonly self: string;
    /**
     * 
     * @type {string}
     * @memberof APIOrganization
     */
    billingAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof APIOrganization
     */
    shippingAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof APIOrganization
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof APIOrganization
     */
    vatNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof APIOrganization
     */
    website?: string;
}

export function APIOrganizationFromJSON(json: any): APIOrganization {
    return APIOrganizationFromJSONTyped(json, false);
}

export function APIOrganizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): APIOrganization {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'self': json['self'],
        'billingAddress': !exists(json, 'billing_address') ? undefined : json['billing_address'],
        'shippingAddress': !exists(json, 'shipping_address') ? undefined : json['shipping_address'],
        'name': json['name'],
        'vatNumber': !exists(json, 'vat_number') ? undefined : json['vat_number'],
        'website': !exists(json, 'website') ? undefined : json['website'],
    };
}

export function APIOrganizationToJSON(value?: APIOrganization | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'billing_address': value.billingAddress,
        'shipping_address': value.shippingAddress,
        'name': value.name,
        'vat_number': value.vatNumber,
        'website': value.website,
    };
}

