/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Base-Serializer for all user types. Deprecated note: Do not use this serializer
directly but, the user type specific serializer, e.g. OrgUserSerializer,
GuestUserSerializer.
 * @export
 * @interface APIAllInOneUser
 */
export interface APIAllInOneUser {
    /**
     * 
     * @type {string}
     * @memberof APIAllInOneUser
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof APIAllInOneUser
     */
    readonly self: string;
    /**
     * 
     * @type {boolean}
     * @memberof APIAllInOneUser
     */
    readonly isGuestUser: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APIAllInOneUser
     */
    readonly isOrgAdmin: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APIAllInOneUser
     */
    readonly isSuperuser: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APIAllInOneUser
     */
    readonly isStaff: boolean;
    /**
     * Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
     * @type {boolean}
     * @memberof APIAllInOneUser
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof APIAllInOneUser
     */
    readonly roles: string;
    /**
     * 
     * @type {string}
     * @memberof APIAllInOneUser
     */
    readonly organization: string;
    /**
     * 
     * @type {string}
     * @memberof APIAllInOneUser
     */
    comment?: string;
    /**
     * 
     * @type {Date}
     * @memberof APIAllInOneUser
     */
    createdAt?: Date;
    /**
     * 
            <ul style="list-style=none;">
                <li>Required for organization users, as the email is used as the username.</li>
                <li>Guest users do *not* require an email address.</li>
            </ul>
            
     * @type {string}
     * @memberof APIAllInOneUser
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIAllInOneUser
     */
    readonly firstName: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIAllInOneUser
     */
    readonly lastName: string | null;
    /**
     * This is the username used for a guest user.
     * @type {string}
     * @memberof APIAllInOneUser
     */
    readonly guestId: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIAllInOneUser
     */
    readonly timeToLive: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIAllInOneUser
     */
    repFor?: string;
}

export function APIAllInOneUserFromJSON(json: any): APIAllInOneUser {
    return APIAllInOneUserFromJSONTyped(json, false);
}

export function APIAllInOneUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): APIAllInOneUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'self': json['self'],
        'isGuestUser': json['is_guest_user'],
        'isOrgAdmin': json['is_org_admin'],
        'isSuperuser': json['is_superuser'],
        'isStaff': json['is_staff'],
        'isActive': !exists(json, 'is_active') ? undefined : json['is_active'],
        'roles': json['roles'],
        'organization': json['organization'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'firstName': json['first_name'],
        'lastName': json['last_name'],
        'guestId': json['guest_id'],
        'timeToLive': json['time_to_live'],
        'repFor': !exists(json, 'rep_for') ? undefined : json['rep_for'],
    };
}

export function APIAllInOneUserToJSON(value?: APIAllInOneUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'is_active': value.isActive,
        'comment': value.comment,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'email': value.email,
        'rep_for': value.repFor,
    };
}

