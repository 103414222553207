/* ------------------------ Functional imports start ------------------------ */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import MultilevelDrawer from './layout/MultilevelDrawer'
import {
  Alert,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material'
import { Delete, Download, ViewInAr } from '@mui/icons-material'
import LogTool from '../logger/logTools'
import { formatBytes, handleAPICallV1, isPrivileged } from '../utils/functions'
import { HTTPMethod } from '../utils/types'
import moment from 'moment'
import { useUserContext } from '../utils/context'
import DeleteModal from './widgets/DeleteModal'
import StlViewerModal from '../features/StlViewer/StlViewerModal'
/* ------------------------- Functional imports end ------------------------- */
/* -------------------------- Design imports start -------------------------- */
/* --------------------------- Design imports end --------------------------- */
type Props = {
  open: boolean
  setOpen: (open: boolean) => void
  onClose?: () => void
  files: any[] | string | undefined
  title?: string
  size?: 'big' | 'small'
}

/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function FilesDrawer(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const { open, setOpen, onClose, files, title, size = 'big' } = props
  const { t } = useTranslation()
  const log = new LogTool({ context: 'FilesDrawer', enable: true, logLevel: 'warn' })
  const { user } = useUserContext()

  /* --------------------------- Non state data end --------------------------- */

  /* ---------------------------- Flag states start --------------------------- */
  const [loading, setLoading] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [openStlViewer, setOpenStlViewer] = useState(false)
  /* ----------------------------- Flag states end ---------------------------- */

  /* ---------------------------- Data states start --------------------------- */
  const [fileList, setFileList] = useState<any[]>([])
  const [deleteFileName, setDeleteFileName] = useState<string>('')
  const [deleteFile, setDeleteFile] = useState<any | undefined>()
  const [stlFileUrl, setStlFileUrl] = useState<string>('')
  /* ----------------------------- Data states end ---------------------------- */

  /* ------------------------------ Effects start ----------------------------- */
  useEffect(() => {
    if(open === true) {
      if(!files) return
      setLoading(true)
      if (typeof files === 'string') {
        fetchObjectFiles(files)
      } else {
        setFileList(files)
      }
      setLoading(false)
    }
  }, [open])
  /* ------------------------------- Effects end ------------------------------ */

  /* ------------------------- Utility functions start ------------------------ */

  const createFile = (fileInput: any) => {
    const file = {
      ...fileInput,
      key: fileInput.self,
      createdAt: new Date(fileInput.created_at).toLocaleString(),
    }
    delete file.created_at;
    return file
  }

  const fetchObjectFiles = async (objectSelf: string) => {
    log.debug('fetchObjectFiles', objectSelf)
    const [response, error] = await handleAPICallV1(HTTPMethod.GET, objectSelf)

    if(!error && response) {
      const files = response.results.map((file: any) => createFile(file))
      log.debug('files', files)
      if(response.next) {
        fetchObjectFiles(response.next)
      }
      setFileList(files)
    } else {
      toast.error(t('common:feedback.error.fetchFailed') || '')
    }
  }

  const handleDownload = async (url: string) => {
    try {
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', extractFilename(url) || '')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      toast.success(t('common:feedback.success.downloadStarted') || '')
    } catch (error) {
      console.error(error)
      toast.error(t('common:feedback.error.downloadFailed') || '')
    }
  }

  const handleDeleteFile = async () => {
    log.debug('handleDeleteFile', deleteFile)
    const [response, error] = await handleAPICallV1(
      HTTPMethod.DELETE,
      deleteFile?.self,
      undefined,
      undefined,
      "text"
    )
    if(!error) {
      toast.success(t('common:feedback.success.deleteFile') || '')
      setOpenDelete(false)
      setFileList(fileList.filter(file => file.self !== deleteFile?.self))
    } else {
      toast.error(t('common:feedback.error.deleteFile') || '')
      setOpenDelete(false)
    }
  }

  /* -------------------------- Utility functions end ------------------------- */

  /* ------------------------ Callback functions start ------------------------ */
  const extractFilename = (url: string) => {
    const urlParts = url?.split('?')[0]
    const pathParts = urlParts?.split('/')
    return pathParts?.pop()
  }
  /* ------------------------- Callback functions end ------------------------- */

  /* ------------------------- Render constants start ------------------------- */
  /* -------------------------- Render constants end -------------------------- */

  /* ------------------------ Pre render actions start ------------------------ */
  /* ------------------------- Pre render actions end ------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <MultilevelDrawer
        open={open}
        setOpen={setOpen}
        onClose={onClose}
        size={size}
        title={title ?? ''}
        confirmButtonProps={{
          text: '',
          onConfirm: async () => {},
          disabled: true,
          hidden: true,
        }}
      >
        <Grid container spacing={2}
          justifyContent="center"
          alignItems="center"
        >
            {fileList.length > 0 && !loading && (
              <Grid item xs={12}>
                <TableContainer>
                  <Table>
                    <TableHead className="table-header">
                      <TableRow>
                        <TableCell>{t('common:content.label.fileName')}</TableCell>
                        <TableCell>{t('common:content.label.size')}</TableCell>
                        <TableCell>{t('common:content.label.createdAt')}</TableCell>
                        <TableCell>{t('common:content.label.action')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {fileList?.map((file, index) => (
                        <TableRow key={index}>
                          <TableCell>{extractFilename(file.document)}</TableCell>
                          <TableCell>{formatBytes(file.size)}</TableCell>
                          <TableCell>{moment(file.stored_at).format('DD.MM.YYYY HH:mm')}</TableCell>
                          <TableCell align="left">
                            <Stack direction="row" spacing={1}>
                              <Tooltip title={t('common:content.label.download') || ''}>
                                <IconButton onClick={() => handleDownload(file.document)}>
                                  <Download />
                                </IconButton>
                              </Tooltip>
                              {isPrivileged(user, "STAFF") && (
                                <Tooltip title={t('common:content.label.delete') || ''}>
                                  <IconButton onClick={() => {
                                    setOpenDelete(true)
                                    setDeleteFileName(extractFilename(file.document) || '')
                                    setDeleteFile(file)
                                  }}>
                                    <Delete />
                                  </IconButton>
                                </Tooltip>
                              )}
                              {file.document.includes(".stl") && (<Tooltip title={t('common:content.label.showStl') || ''}>
                                <IconButton
                                  onClick={() => {
                                    setStlFileUrl(file.document)
                                    setOpenStlViewer(true)
                                  }}
                                >
                                  <ViewInAr />
                                </IconButton>
                              </Tooltip>)}
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )}
            {fileList.length === 0 && (
              <Grid item xs={12}>
                <Alert severity="info">
                  {t('common:content.label.noFilesFound') || ''}
                </Alert>
              </Grid>
            )}
            {loading && (
              <Grid item xs={12}>
                <Grid container
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <Grid item xs={12}>
                    <CircularProgress
                      sx={{
                        margin: '0 auto',
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <p>{t('common:content.label.loadingFiles') || ''}</p>
                  </Grid>
                </Grid>
              </Grid>
            )}
        </Grid>
      </MultilevelDrawer>
      <StlViewerModal
        open={openStlViewer}
        setOpen={setOpenStlViewer}
        url={stlFileUrl}
      />
      <DeleteModal
        name={deleteFileName}
        open={openDelete}
        setOpen={setOpenDelete}
        onDelete={handleDeleteFile}
      />
    </>
  )
}
