/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A collection of serializer extensions, which allow for:

* Blacklisting fields through an "exclude" context variable
* Whitelisting fields through an "only" context variable
* Expanding related fields through an "expand" context variable
* Other helper methods

Blacklisting and whitelisting takes precedence over expanding, and can
be used in combination with one another.
 * @export
 * @interface APIArticle
 */
export interface APIArticle {
    /**
     * 
     * @type {string}
     * @memberof APIArticle
     */
    readonly self: string;
    /**
     * 
     * @type {string}
     * @memberof APIArticle
     */
    weight?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIArticle
     */
    length?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIArticle
     */
    width?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIArticle
     */
    height?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIArticle
     */
    volume?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIArticle
     */
    leadTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIArticle
     */
    readonly inStorage: string;
    /**
     * 
     * @type {string}
     * @memberof APIArticle
     */
    articleType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIArticle
     */
    family?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIArticle
     */
    brand?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIArticle
     */
    barcodeType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIArticle
     */
    supplier?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof APIArticle
     */
    category?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof APIArticle
     */
    readonly variationOf: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof APIArticle
     */
    properties?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof APIArticle
     */
    readonly files: string;
    /**
     * 
     * @type {string}
     * @memberof APIArticle
     */
    guestRequest?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIArticle
     */
    readonly hasChildren: boolean;
    /**
     * 
     * @type {string}
     * @memberof APIArticle
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof APIArticle
     */
    number: string;
    /**
     * 
     * @type {Date}
     * @memberof APIArticle
     */
    readonly creationDate: Date;
    /**
     * 
     * @type {string}
     * @memberof APIArticle
     */
    barcodeText?: string;
    /**
     * 
     * @type {string}
     * @memberof APIArticle
     */
    countryOfOrigin?: string;
    /**
     * 
     * @type {number}
     * @memberof APIArticle
     */
    minimumStorage?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIArticle
     */
    minimumOrder?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIArticle
     */
    batchSize?: number | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof APIArticle
     */
    manufacturingProcess?: { [key: string]: any; };
    /**
     * 
     * @type {string}
     * @memberof APIArticle
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof APIArticle
     */
    isBomBase?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APIArticle
     */
    isImportedVersion?: boolean;
}

export function APIArticleFromJSON(json: any): APIArticle {
    return APIArticleFromJSONTyped(json, false);
}

export function APIArticleFromJSONTyped(json: any, ignoreDiscriminator: boolean): APIArticle {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'self': json['self'],
        'weight': !exists(json, 'weight') ? undefined : json['weight'],
        'length': !exists(json, 'length') ? undefined : json['length'],
        'width': !exists(json, 'width') ? undefined : json['width'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'volume': !exists(json, 'volume') ? undefined : json['volume'],
        'leadTime': !exists(json, 'lead_time') ? undefined : json['lead_time'],
        'inStorage': json['in_storage'],
        'articleType': !exists(json, 'article_type') ? undefined : json['article_type'],
        'family': !exists(json, 'family') ? undefined : json['family'],
        'brand': !exists(json, 'brand') ? undefined : json['brand'],
        'barcodeType': !exists(json, 'barcode_type') ? undefined : json['barcode_type'],
        'supplier': !exists(json, 'supplier') ? undefined : json['supplier'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'variationOf': json['variation_of'],
        'properties': !exists(json, 'properties') ? undefined : json['properties'],
        'files': json['files'],
        'guestRequest': !exists(json, 'guest_request') ? undefined : json['guest_request'],
        'hasChildren': json['has_children'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'number': json['number'],
        'creationDate': (new Date(json['creation_date'])),
        'barcodeText': !exists(json, 'barcode_text') ? undefined : json['barcode_text'],
        'countryOfOrigin': !exists(json, 'country_of_origin') ? undefined : json['country_of_origin'],
        'minimumStorage': !exists(json, 'minimum_storage') ? undefined : json['minimum_storage'],
        'minimumOrder': !exists(json, 'minimum_order') ? undefined : json['minimum_order'],
        'batchSize': !exists(json, 'batch_size') ? undefined : json['batch_size'],
        'manufacturingProcess': !exists(json, 'manufacturing_process') ? undefined : json['manufacturing_process'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'isBomBase': !exists(json, 'is_bom_base') ? undefined : json['is_bom_base'],
        'isImportedVersion': !exists(json, 'is_imported_version') ? undefined : json['is_imported_version'],
    };
}

export function APIArticleToJSON(value?: APIArticle | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'weight': value.weight,
        'length': value.length,
        'width': value.width,
        'height': value.height,
        'volume': value.volume,
        'lead_time': value.leadTime,
        'article_type': value.articleType,
        'family': value.family,
        'brand': value.brand,
        'barcode_type': value.barcodeType,
        'supplier': value.supplier,
        'category': value.category,
        'properties': value.properties,
        'guest_request': value.guestRequest,
        'name': value.name,
        'number': value.number,
        'barcode_text': value.barcodeText,
        'country_of_origin': value.countryOfOrigin,
        'minimum_storage': value.minimumStorage,
        'minimum_order': value.minimumOrder,
        'batch_size': value.batchSize,
        'manufacturing_process': value.manufacturingProcess,
        'description': value.description,
        'is_bom_base': value.isBomBase,
        'is_imported_version': value.isImportedVersion,
    };
}

