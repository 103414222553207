/* -------------------------- Design imports start -------------------------- */
import {
  Box,
  Grid,
  IconButton,
  Step,
  StepIcon,
  StepLabel,
  Stepper,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
/* --------------------------- Design imports end --------------------------- */
/* ------------------------ Functional imports start ------------------------ */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import LogTool from '../../../logger/logTools'
import { FileInfo, FileUploadStatus } from '../../../utils/types'
import {
  deleteFile,
  isPrivileged,
  missingRequiredFormFields,
  uploadFile,
} from '../../../utils/functions'
import GetConstant from '../utils/constants'
import { Form, FormFieldType } from '../../Form'
import MultilevelDrawer from '../../../components/layout/MultilevelDrawer'
import FileInput from '../../../components/inputs/FileInput'
import InputFileList from '../../../components/widgets/InputFileList'
import { updateRequest } from '../utils/functions'
import { PriceObject } from '../utils/types'
import { useUserContext } from '../../../utils/context'
import Button from '../../../components/Button'
import { Add, Delete } from '@mui/icons-material'
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  open: boolean
  setOpen: (open: boolean) => void
  formState: {
    state: any | undefined
    setState: React.Dispatch<React.SetStateAction<any | undefined>>
  }
  onClose?: () => void
  onCancel?: () => void
  onCreateConfirm?: (a: any) => Promise<void>
  enableEditMode?: boolean
  onEditConfirm?: (input: any) => void
  setTabValue?: (value: number) => void
}

/* -------------------------------------------------------------------------- */
/*                               Start component                              */
/* -------------------------------------------------------------------------- */
export default function OfferDrawer(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const {
    open,
    setOpen,
    formState,
    onClose = () => null,
    onCancel = () => null,
    onCreateConfirm = () => null,
    enableEditMode = false,
    onEditConfirm = () => null,
    setTabValue,
  } = props

  const customTheme = createTheme({
    components: {
      MuiStepIcon: {
        styleOverrides: {
          root: {
            '&.Mui-active': {
              color: '#16a9e4',
            },
            '&.Mui-completed': {
              color: '#16a9e4',
            },
          },
          text: {
            color: 'white', // Set the text color to white for all steps
          },
        },
      },
    },
  })
  const { t } = useTranslation(['request', 'common'])
  const log = new LogTool({ context: 'OfferDrawer', enable: true, logLevel: 'warn' })
  const steps = [t('common:content.label.generalInformation'), t('common:content.label.upload')]
  const { user } = useUserContext()
  /* --------------------------- Non state data end --------------------------- */

  /* ---------------------------- Flag states start --------------------------- */
  const [waitingOnAction, setWaiting] = useState(false)
  const [prevOpen, setPrevOpen] = useState<boolean>(false)
  /* ----------------------------- Flag states end ---------------------------- */

  /* ---------------------------- Data states start --------------------------- */
  const [fileInput, setFileInput] = useState<FileInfo[]>([])
  const [skippedSteps, setSkippedSteps] = useState(new Set<number>())
  const [activeStep, setActiveStep] = useState<number>(0)
  const [quantities, setQuantities] = useState<string[]>([''])
  const [prices, setPrices] = useState<PriceObject[]>([])
  /* ----------------------------- Data states end ---------------------------- */

  /* ------------------------------ Effects start ----------------------------- */
  useEffect(() => {
    if (open && !prevOpen) {
      setPrevOpen(true)
    }
  }, [open])
  /* ------------------------------- Effects end ------------------------------ */

  /* ------------------------- Utility functions start ------------------------ */
  /**
   * Setter for the uploadStatus property on a FileInfo object within this component.
   * Do not use this function if you want to update multiple files at once as the component is rerendered after every status update!
   * @param file the file on which the uploadStatus should be updated.
   * @param status the updated uploadStatus.
   */
  function setFileUploadStatus(file: FileInfo, status: FileUploadStatus) {
    setFileInput((prev: FileInfo[]) =>
      prev.map((listedFile: FileInfo) => {
        if (listedFile.name === file.name) {
          // add an upload-status of success to the file info object or overwrite it with the provided status
          return Object.assign(file, { uploadStatus: status })
        }
        return listedFile
      })
    )
  }

  /**
   * Wrapper for the uploadFile function that specifies the onSuccess and onError callbacks.
   * This function simply allows us to upload files using the same behavior at multiple places
   * within this component without rewriting the callback functions all the time.
   * @param file the file to upload.
   * @param url the url to upload the file to.
   */
  async function uploadRequestFile(file: FileInfo, url: string) {
    uploadFile({
      url: url,
      file: file as File,
      onSuccess: (response: FileInfo) => {
        // update the listed file
        setFileInput(prev =>
          prev.map((listedFile: FileInfo) => {
            if (listedFile.name === file.name) {
              return { ...response, uploadStatus: 'success' }
            }
            return listedFile
          })
        )
      },
      onError: () => {
        // update the uploadStatus of the file
        setFileUploadStatus(file, 'uploadError')
      },
    })
  }
  /**
   * Check if all required FormFields of the current step are filled in correclty.
   * @param activeStep
   * @returns
   */
  const nextStepDisabled = (activeStep: number): boolean => {
    if (activeStep === 0) {
      return missingRequiredFormFields([...offerFormFields], formState.state)
    }
    return false
  }

  /* -------------------------- Utility functions end ------------------------- */

  /* ------------------------ Callback functions start ------------------------ */

  const handleCreateOffer = async () => {
    setWaiting(true)
    log.debug('handleCreateOffer -> ', formState.state)
    if (fileInput.length > 0) {
      setFileInput((prev: FileInfo[]) =>
        prev.map(file => Object.assign(file, { uploadStatus: 'loading' }))
      )
    }
    // posting offer to server and waiting on response
    const offerFilesUrl = await onCreateConfirm(formState.state)

    log.debug('offerFilesUrl -> ', offerFilesUrl)
    // check if the user specified files to upload !
    if (fileInput.length > 0 && offerFilesUrl) {
      // upload the files one by one for a more detailed feedback if something goes wrong,
      // and to make use parallel uploading to decrease upload time
      for (const file of fileInput) {
        uploadRequestFile(file, offerFilesUrl)
      }
    }
    setWaiting(false)
    handleClose()
    const filteredQuantities = quantities.filter(item => item !== '')
    const allQuantities = [...formState.state.request.quantities, ...filteredQuantities]
    updateRequest(
      {
        ...formState.state.request,
        status: 1,
        quantities: allQuantities,
        prices: formState.state.prices,
      },
      [],
      () => {
        log.debug('Success updating request')
      },
      (error: any) => {
        log.debug('Error on updating request')
      }
    )
    setTabValue && setTabValue(isPrivileged(user, 'STAFF') ? 2 : 1)
  }

  const handleUpdateOffer = async () => {
    log.debug('handleUpdateOffer -> ', formState.state)
    /* const offer = TODO
  log.debug("Offer -> ", offer)
  updateOffer(
    offer,
    [],
    () => {
      log.debug("Success updating offer")
      toast.success(t("request:feedback.success.updateOffer"))
    },
    (error: any) => {
      log.debug("Error on updating offer")
      toast.success(t("request:feedback.success.updateOffer"))
    }
  ) */
  }

  const handleOffer = (operation: 'update' | 'create') => {
    log.debug('handleOffer -> ', operation)
    operation === 'create' ? handleCreateOffer() : handleUpdateOffer()
    handleClose(true)
  }

  /**
   * Remove a file from fileInput list before it is uploaded
   * @param file
   */
  const handleRemoveFileFromList = (file: FileInfo) => {
    // update rendered files list
    setFileInput(prevFileList => {
      // get the location of the file to remove within the files list
      const fileIndex = prevFileList.findIndex(listedFile => listedFile.name === file.name)
      // remove the file from the list. Note that this does not work on the prevFileList directly
      let updatedFileList = [...prevFileList]
      updatedFileList.splice(fileIndex, 1)

      // return the files list where the file is removed
      return updatedFileList
    })
  }
  /**
   * (Re)upload a file to the specified url.
   * @param file The file to upload
   * @param url The url to upload the file to.
   */
  const handleReUploadFile = async (file: FileInfo, url: string) => {
    // set the uploadStatus of the current file to loading
    setFileUploadStatus(file, 'loading')
    // retry to upload the file
    uploadRequestFile(file, url)
  }

  /**
   * Delete a file that was uploaded already.
   * @param file The file to delete.
   */
  const handleDeleteFile = async (file: FileInfo) => {
    // set the uploadStatus of the current file to loading
    setFileUploadStatus(file, 'loading')
    // try to delete the file
    deleteFile({
      url: file.self as string,
      onSuccess: () => {
        handleRemoveFileFromList(file)
      },
      onError: () => {
        setFileUploadStatus(file, 'deleteError')
      },
    })
  }

  const isStepOptional = (step: number) => {
    return step === 4
  }

  const isStepSkipped = (step: number) => {
    return skippedSteps.has(step)
  }

  const handleNext = () => {
    let newSkipped = skippedSteps
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1)
    setSkippedSteps(newSkipped)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.")
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1)
    setSkippedSteps(prevSkipped => {
      const newSkipped = new Set(prevSkipped.values())
      newSkipped.add(activeStep)
      return newSkipped
    })
  }

  const handleClose = (preventOnClose: boolean = false) => {
    // reset form state
    setWaiting(false)
    setQuantities([''])
    setPrices([])
    formState.setState({})
    setFileInput([])
    setActiveStep(0)

    setOpen(false)
    setPrevOpen(false)
    !preventOnClose && onClose()
  }
  /* ------------------------- Callback functions end ------------------------- */

  /* ------------------------- Render constants start ------------------------- */
  const offerFormFieldsDisabled = GetConstant({
    name: 'offerFormFieldsDisabled',
  }) as FormFieldType[]
  const offerFormFields = GetConstant({
    name: 'offerFormFields',
  }) as FormFieldType[]

  const CustomStepIcon = (props: any) => {
    return (
      <ThemeProvider theme={customTheme}>
        <StepIcon {...props} />
      </ThemeProvider>
    )
  }
  /* -------------------------- Render constants end -------------------------- */

  /* ------------------------ Pre render actions start ------------------------ */
  /* ------------------------- Pre render actions end ------------------------- */

  log.debug('FormInput -> ', formState.state)
  log.debug('quantities -> ', quantities)

  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <MultilevelDrawer
        open={open}
        setOpen={setOpen}
        onClose={handleClose}
        size="big"
        title={t('common:content.label.createOffer') as string}
        customHeader={
          <Box sx={{ display: 'flex', width: '100%' }}>
            <Typography variant="h6" noWrap sx={{ flexShrink: 0, mr: '2rem' }}>
              {enableEditMode
                ? t('request:content.label.editOffer')
                : t('common:content.label.createOffer')}
            </Typography>
            <Stepper
              activeStep={activeStep}
              sx={{ marginRight: '16px', marginLeft: '-8px', width: '100%' }}
            >
              {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {}
                const labelProps: { optional?: React.ReactNode } = {}
                if (isStepOptional(index)) {
                  labelProps.optional = <Typography variant="caption">Optional</Typography>
                }
                if (isStepSkipped(index)) {
                  stepProps.completed = false
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel StepIconComponent={CustomStepIcon} {...labelProps}>
                      {label}
                    </StepLabel>
                  </Step>
                )
              })}
            </Stepper>
          </Box>
        }
        customActions={
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            {activeStep === 0 ? (
              <Button
                id="cancelButton"
                onClick={() => setOpen(false)}
                color="inherit"
                variant="contained"
                disabled={waitingOnAction}
                sx={{ mr: 1 }}
              >
                {t('common:interaction.button.cancel')}
              </Button>
            ) : (
              <Button
                id="backButton"
                color="inherit"
                variant="contained"
                disabled={waitingOnAction}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                {t('common:interaction.button.back')}
              </Button>
            )}
            {isStepOptional(activeStep) && (
              <Button id="skipButton" color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                {t('common:interaction.button.skip')}
              </Button>
            )}
            {activeStep < steps.length - 1 ? (
              <Button
                id="nextButton"
                variant="contained"
                sx={{ marginLeft: '10px' }}
                disabled={nextStepDisabled(activeStep)}
                onClick={handleNext}
              >
                {t('common:interaction.button.next')}
              </Button>
            ) : (
              <LoadingButton
                id="createButton"
                variant="contained"
                color="primary"
                sx={{ marginLeft: '10px', color: 'whitesmoke', textTransform: 'none' }}
                disabled={nextStepDisabled(activeStep)}
                loading={waitingOnAction}
                onClick={() => {
                  handleOffer(enableEditMode ? 'update' : 'create')
                }}
              >
                {enableEditMode
                  ? t('common:interaction.button.save')
                  : t('common:interaction.button.create')}
              </LoadingButton>
            )}
          </Box>
        }
      >
        <React.Fragment>
          {activeStep === 0 && (
            <>
              <Typography variant="h6" style={{ marginBottom: '10px', marginTop: '20px' }}>
                {t('common:content.label.generalInformation')}
              </Typography>
              <Form
                sx={{ marginBottom: '20px' }}
                formObject={formState.state?.request?.article ?? { name: '', number: '' }}
                formFields={offerFormFieldsDisabled}
                editing={false}
                onChange={() => {}}
              />
              <Grid container spacing={1} marginBottom={'20px'}>
                {formState.state?.prices &&
                  formState.state?.request?.quantities &&
                  formState.state.request?.quantities?.map((quantity: number, index: number) => {
                    return (
                      <>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                          <TextField
                            id="quantity-1"
                            label={t('request:content.label.quantity') + ` ${index + 1}`}
                            type="text"
                            variant="outlined"
                            fullWidth
                            value={quantity}
                            onChange={e => setQuantities([e.target.value, ...quantities.slice(1)])}
                            required
                            disabled
                            error={quantity === undefined || !/^\d+$/.test(quantity.toString())}
                            helperText={
                              quantity === undefined
                                ? t('request:feedback.error.pleaseEnterQuantity')
                                : !/^\d+$/.test(quantity.toString())
                                ? t('request:feedback.error.invalidQuantity')
                                : ''
                            }
                            inputProps={{
                              inputMode: 'numeric',
                              pattern: '[0-9]*',
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            id={`price-${index + 1}`}
                            type="text"
                            label={t('request:content.label.price') + ` ${index + 1}`}
                            value={formState.state?.prices[quantity] ?? ''}
                            onChange={e => {
                              formState.setState({
                                ...formState.state,
                                prices: {
                                  ...formState.state.prices,
                                  [quantity]: e.target.value.replace(',', '.'),
                                },
                              })
                            }}
                            required={index === 0}
                            error={
                              (index === 0 && formState.state?.prices[quantity] === '') ||
                              (formState.state?.prices[quantity] !== '' &&
                                !/^[0-9]*[.,]?[0-9]*$/.test(formState.state?.prices[quantity]))
                            }
                            helperText={
                              index === 0 && formState.state?.prices[quantity] === ''
                                ? t('request:feedback.error.pleaseEnterPrice')
                                : formState.state?.prices[quantity] !== '' &&
                                  !/^[0-9]*[.,]?[0-9]*$/.test(formState.state?.prices[quantity])
                                ? t('request:feedback.error.invalidPrice')
                                : ''
                            }
                            inputProps={{
                              inputMode: 'decimal',
                              pattern: '[0-9]*[.,]?[0-9]*',
                            }}
                            InputProps={{
                              endAdornment: <div>€</div>,
                            }}
                            fullWidth
                          />
                        </Grid>
                      </>
                    )
                  })}
                {quantities?.slice(1)?.map((quantity, index) => (
                  <>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} key={index}>
                      <TextField
                        id={`quantity-${index}`}
                        label={t('request:content.label.quantity') + ` ${index + 2}`}
                        type="text"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              onClick={() =>
                                setQuantities([
                                  ...quantities.slice(0, index),
                                  ...quantities.slice(index + 1),
                                ])
                              }
                              style={{ color: 'grey' }}
                              size="small"
                              aria-label="add"
                            >
                              <Delete />
                            </IconButton>
                          ),
                        }}
                        value={quantity}
                        error={quantities[index + 1] !== '' && !/^\d+$/.test(quantities[index + 1])}
                        helperText={
                          quantities[index + 1] !== '' && !/^\d+$/.test(quantities[index + 1])
                            ? t('request:feedback.error.invalidQuantity')
                            : ''
                        }
                        onChange={e => {
                          const newQuantities = [...quantities]
                          newQuantities[index + 1] = e.target.value
                          setQuantities(newQuantities)
                        }}
                        inputProps={{
                          inputMode: 'numeric',
                          pattern: '[0-9]*',
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <TextField
                        id={`price-${index + 2}`}
                        type="text"
                        label={t('request:content.label.price') + ` ${index + 2}`}
                        value={formState.state?.prices[quantity] ?? ''}
                        onChange={e => {
                          formState.setState({
                            ...formState.state,
                            prices: {
                              ...formState.state.prices,
                              [quantity]: e.target.value.replace(',', '.'),
                            },
                          })
                        }}
                        required={index === 0}
                        error={
                          (index === 0 && formState.state?.prices[index]?.price === '') ||
                          (formState.state?.prices[index]?.price !== '' &&
                            !/^[0-9]*[.,]?[0-9]*$/.test(formState.state?.prices[index]?.price))
                        }
                        helperText={
                          index === 0 && formState.state?.prices[index]?.price === ''
                            ? t('request:feedback.error.pleaseEnterPrice')
                            : formState.state?.prices[index]?.price !== '' &&
                              !/^[0-9]*[.,]?[0-9]*$/.test(formState.state?.prices[index]?.price)
                            ? t('request:feedback.error.invalidPrice')
                            : ''
                        }
                        inputProps={{
                          inputMode: 'decimal',
                          pattern: '[0-9]*[.,]?[0-9]*',
                        }}
                        InputProps={{
                          endAdornment: <div>€</div>,
                        }}
                        fullWidth
                      />
                    </Grid>
                  </>
                ))}
                {quantities.length < 10 && (
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Button
                      onClick={() => setQuantities([...quantities, ''])}
                      color="primary"
                      variant="text"
                      startIcon={<Add />}
                      size="large"
                      aria-label="add"
                      style={{ marginBottom: '20px' }}
                    >
                      {t('request:interaction.button.newQuantity')}
                    </Button>
                  </Grid>
                )}
              </Grid>
              <Form
                formObject={formState.state}
                formFields={offerFormFields}
                onChange={value => formState.setState(value as any)}
                editing={true}
              />
            </>
          )}
          {activeStep === 1 && (
            <Grid>
              <Typography variant="h6" sx={{ marginTop: '15px', marginBottom: '10px' }}>
                {t('common:content.label.documents')}
              </Typography>
              <FileInput
                inputState={{ state: fileInput, setState: setFileInput }}
                maxAllowedFiles={10}
              />
              {fileInput.length > 0 && (
                <InputFileList
                  files={fileInput}
                  noFilesInfo=""
                  enableEditMode={false}
                  onRemoveFile={handleRemoveFileFromList}
                  onReUploadFile={handleReUploadFile}
                  onDeleteFile={handleDeleteFile}
                />
              )}
            </Grid>
          )}
        </React.Fragment>
      </MultilevelDrawer>
    </>
  )
}
