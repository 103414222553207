/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Guest-User specific serializer that is limited to the Guest-User
specific fields.
 * @export
 * @interface APIGuestUser
 */
export interface APIGuestUser {
    /**
     * 
     * @type {string}
     * @memberof APIGuestUser
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof APIGuestUser
     */
    readonly self: string;
    /**
     * 
     * @type {boolean}
     * @memberof APIGuestUser
     */
    readonly isGuestUser: boolean;
    /**
     * This is the username used for a guest user.
     * @type {string}
     * @memberof APIGuestUser
     */
    readonly guestId: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGuestUser
     */
    readonly organization: string;
    /**
     * 
     * @type {Date}
     * @memberof APIGuestUser
     */
    createdAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof APIGuestUser
     */
    readonly timeToLive: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIGuestUser
     */
    repFor?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGuestUser
     */
    comment?: string;
    /**
     * Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
     * @type {boolean}
     * @memberof APIGuestUser
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof APIGuestUser
     */
    readonly roles: string;
}

export function APIGuestUserFromJSON(json: any): APIGuestUser {
    return APIGuestUserFromJSONTyped(json, false);
}

export function APIGuestUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): APIGuestUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'self': json['self'],
        'isGuestUser': json['is_guest_user'],
        'guestId': json['guest_id'],
        'organization': json['organization'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'timeToLive': json['time_to_live'],
        'repFor': !exists(json, 'rep_for') ? undefined : json['rep_for'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'isActive': !exists(json, 'is_active') ? undefined : json['is_active'],
        'roles': json['roles'],
    };
}

export function APIGuestUserToJSON(value?: APIGuestUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'rep_for': value.repFor,
        'comment': value.comment,
        'is_active': value.isActive,
    };
}

