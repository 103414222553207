/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A collection of serializer extensions, which allow for:

* Blacklisting fields through an "exclude" context variable
* Whitelisting fields through an "only" context variable
* Expanding related fields through an "expand" context variable
* Other helper methods

Blacklisting and whitelisting takes precedence over expanding, and can
be used in combination with one another.
 * @export
 * @interface APICustomer
 */
export interface APICustomer {
    /**
     * 
     * @type {string}
     * @memberof APICustomer
     */
    readonly self: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof APICustomer
     */
    contacts?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof APICustomer
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof APICustomer
     */
    ustId?: string;
}

export function APICustomerFromJSON(json: any): APICustomer {
    return APICustomerFromJSONTyped(json, false);
}

export function APICustomerFromJSONTyped(json: any, ignoreDiscriminator: boolean): APICustomer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'self': json['self'],
        'contacts': !exists(json, 'contacts') ? undefined : json['contacts'],
        'name': json['name'],
        'ustId': !exists(json, 'ust_id') ? undefined : json['ust_id'],
    };
}

export function APICustomerToJSON(value?: APICustomer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contacts': value.contacts,
        'name': value.name,
        'ust_id': value.ustId,
    };
}

