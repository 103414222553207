/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    APIContractStatusEnum,
    APIContractStatusEnumFromJSON,
    APIContractStatusEnumFromJSONTyped,
    APIContractStatusEnumToJSON,
} from './APIContractStatusEnum';

/**
 * A collection of serializer extensions, which allow for:

* Blacklisting fields through an "exclude" context variable
* Whitelisting fields through an "only" context variable
* Expanding related fields through an "expand" context variable
* Other helper methods

Blacklisting and whitelisting takes precedence over expanding, and can
be used in combination with one another.
 * @export
 * @interface APIContract
 */
export interface APIContract {
    /**
     * 
     * @type {string}
     * @memberof APIContract
     */
    readonly self: string;
    /**
     * 
     * @type {string}
     * @memberof APIContract
     */
    offer: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof APIContract
     */
    editors?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof APIContract
     */
    readonly files: string;
    /**
     * 
     * @type {string}
     * @memberof APIContract
     */
    shippingAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof APIContract
     */
    billingAddress?: string;
    /**
     * 
     * @type {Date}
     * @memberof APIContract
     */
    desiredDelivery?: Date;
    /**
     * 
     * @type {Date}
     * @memberof APIContract
     */
    latestDelivery?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof APIContract
     */
    priorityShipping?: boolean;
    /**
     * 
     * @type {APIContractStatusEnum}
     * @memberof APIContract
     */
    contractStatus?: APIContractStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof APIContract
     */
    comment?: string | null;
    /**
     * Price that gets selected by the user from the Prices of the Offer. Format: {'200': '123.45'}
     * @type {{ [key: string]: any; }}
     * @memberof APIContract
     */
    price?: { [key: string]: any; };
    /**
     * 
     * @type {Date}
     * @memberof APIContract
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof APIContract
     */
    readonly article: string;
    /**
     * 
     * @type {string}
     * @memberof APIContract
     */
    readonly request: string;
    /**
     * 
     * @type {string}
     * @memberof APIContract
     */
    readonly read: string;
}

export function APIContractFromJSON(json: any): APIContract {
    return APIContractFromJSONTyped(json, false);
}

export function APIContractFromJSONTyped(json: any, ignoreDiscriminator: boolean): APIContract {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'self': json['self'],
        'offer': json['offer'],
        'editors': !exists(json, 'editors') ? undefined : json['editors'],
        'files': json['files'],
        'shippingAddress': !exists(json, 'shipping_address') ? undefined : json['shipping_address'],
        'billingAddress': !exists(json, 'billing_address') ? undefined : json['billing_address'],
        'desiredDelivery': !exists(json, 'desired_delivery') ? undefined : (new Date(json['desired_delivery'])),
        'latestDelivery': !exists(json, 'latest_delivery') ? undefined : (new Date(json['latest_delivery'])),
        'priorityShipping': !exists(json, 'priority_shipping') ? undefined : json['priority_shipping'],
        'contractStatus': !exists(json, 'contract_status') ? undefined : APIContractStatusEnumFromJSON(json['contract_status']),
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'createdAt': (new Date(json['created_at'])),
        'article': json['article'],
        'request': json['request'],
        'read': json['read'],
    };
}

export function APIContractToJSON(value?: APIContract | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'offer': value.offer,
        'editors': value.editors,
        'shipping_address': value.shippingAddress,
        'billing_address': value.billingAddress,
        'desired_delivery': value.desiredDelivery === undefined ? undefined : (value.desiredDelivery.toISOString()),
        'latest_delivery': value.latestDelivery === undefined ? undefined : (value.latestDelivery.toISOString()),
        'priority_shipping': value.priorityShipping,
        'contract_status': APIContractStatusEnumToJSON(value.contractStatus),
        'comment': value.comment,
        'price': value.price,
    };
}

